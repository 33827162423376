import React from 'react'
import { Link } from 'gatsby'
import Toggle from './toggle'

class Navigation extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      toggle : false,
      showNav : false,
      colorUp : false,
    };
    this.toggleNav = this.toggleNav.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.desactivateNav = this.desactivateNav.bind(this);
  };

  render() {
    return (
      <nav role="navigation" className={`${this.state.colorUp ? 'bg-white shadow dark:bg-secondaryDark' : ''} fixed w-full z-10 top-0`} aria-label="Main">
      <div className="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
        <div>
          <Link to="/" onClick={this.desactivateNav} className="pl-4">
            <a className="text-gray-900 dark:text-white no-underline hover:no-underline font-black text-xl">Salvador Valverde</a>
          </Link>
        </div>
        <div class="flex lg:hidden pr-4 items-center">
          <div className="pr-3">
            <Toggle />
          </div>
          <button
            onClick={this.toggleNav}
            id="nav-toggle"
            class="flex items-center px-3 py-2 border rounded text-gray-500 dark:text-gray-300 border-gray-600 dark:border-gray-300 hover:text-gray-900 appearance-none focus:outline-none"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div  id="nav-content" className={` ${this.state.showNav ? 'h-32' : ' h-0 lg:h-auto' } transition-height ease-linear overflow-hidden duration-9000 w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0  md:bg-transparent z-20`}>
          <ul className="list-reset lg:flex justify-end flex-1 items-center  ">
            <li>
              <Link onClick={this.desactivateNav} to="/" className="nav-btn" >
                Home
              </Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={this.desactivateNav} className="nav-btn" >
                Portfolio
              </Link>
            </li>
            {/* <li>
              <Link to="/blog"  className="nav-btn">
                Blog
              </Link>
            </li> */}
            <li>
              <Link to="/contact" onClick={this.desactivateNav} className="nav-btn">
                Contact
              </Link>
            </li>
            <li className={`hidden lg:block`}>
              <Toggle></Toggle>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    );
  }

  toggleNav() {
    console.log(this.state.showNav);
    if (this.state.toggle == false) {
      this.setState({toggle : true,showNav:true,colorUp :true})
    } else {
      this.setState({toggle : false,showNav:false})
    }
  };

  desactivateNav(){
    this.setState({toggle : false,showNav:false})
  }

  componentDidMount(){
      window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount(){
      window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll() {
    var scrollpos = window.scrollY;
    if (scrollpos > 10) {
       console.log("Scroll")
      this.setState({colorUp:true})
    } else {
      if (!this.state.showNav)this.setState({colorUp:false})
    }
  };
}

export default Navigation
