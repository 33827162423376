import React from 'react'

import '../components/variables.css'
import Seo from '../components/seo'
import Navigation from '../components/navigation'
import Footer from '../components/footer'
import useIsMounted from '../components/useIsMounted'
import { ThemeProvider } from '../components/theme-context'
import Transition from '../components/transition'
class Template extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      mounted :false
    };
  }

  render() {
    const { children, location} = this.props
    return (

        <ThemeProvider>
        <div className={`${this.state.mounted ? 'animation-colors ease-linear duration-500' : ''} scroll-smooth bg-zinc-100 dark:bg-primaryDark min-h-screen`}>
            <Seo />
            <Navigation/>
            <Transition location={location}>
            <main className="">{children}</main>
            </Transition>
            <Footer />
        </div>
        </ThemeProvider>
    )
  }
  
  componentDidMount() {
    setTimeout(function() {
      this.setState({mounted:true})
    }.bind(this), 1000)
  }
}

export default Template
